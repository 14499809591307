// utils.js
// Function to extract the domain from the current URL
const getDomain = () => {
  const { hostname } = window.location;
  return hostname;
};

const domain = getDomain();
// const domain = "the-valley.community"; // Replace with the correct base URL if needed
export default domain;
