import React from "react";
import Header from "../componnents/layout-components/Header/Header";

function ThankYou() {
  return (
    <div>
      <Header />
      <div className="thankyou-main">
        <h2>Thank you for getting in touch!</h2>
        <p>
          We appreciate you contacting us. One of our colleagues will get back
          in touch with you soon! Have a great day!
        </p>
      </div>
    </div>
  );
}

export default ThankYou;
